// Generated by script, don't edit it please.
import createSvgIcon from '../createSvgIcon';
import CloseSvg from '../icons/application/Close';
var Close = createSvgIcon({
    as: CloseSvg,
    ariaLabel: 'close',
    category: 'application',
    displayName: 'Close'
});
export default Close;
