// Generated by script, don't edit it please.
import createSvgIcon from '../../createSvgIcon';
import AngleLeftSvg from '@rsuite/icon-font/lib/legacy/AngleLeft';
/**
 * @deprecated Will be removed in the next major version.
 */ var AngleLeft = createSvgIcon({
    as: AngleLeftSvg,
    ariaLabel: 'angle left',
    category: '',
    displayName: 'AngleLeft'
});
export default AngleLeft;
