// Generated by script, don't edit it please.
import createSvgIcon from '../../createSvgIcon';
import AngleDownSvg from '@rsuite/icon-font/lib/legacy/AngleDown';
/**
 * @deprecated Will be removed in the next major version.
 */ var AngleDown = createSvgIcon({
    as: AngleDownSvg,
    ariaLabel: 'angle down',
    category: '',
    displayName: 'AngleDown'
});
export default AngleDown;
