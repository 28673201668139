// Generated by script, don't edit it please.
import createSvgIcon from '../../createSvgIcon';
import AngleRightSvg from '@rsuite/icon-font/lib/legacy/AngleRight';
/**
 * @deprecated Will be removed in the next major version.
 */ var AngleRight = createSvgIcon({
    as: AngleRightSvg,
    ariaLabel: 'angle right',
    category: '',
    displayName: 'AngleRight'
});
export default AngleRight;
