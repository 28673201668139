// Generated by script, don't edit it please.
import createSvgIcon from '../createSvgIcon';
import SearchSvg from '../icons/action/Search';
var Search = createSvgIcon({
    as: SearchSvg,
    ariaLabel: 'search',
    category: 'action',
    displayName: 'Search'
});
export default Search;
