// Generated by script, don't edit it please.
import createSvgIcon from '../../createSvgIcon';
import AngleUpSvg from '@rsuite/icon-font/lib/legacy/AngleUp';
/**
 * @deprecated Will be removed in the next major version.
 */ var AngleUp = createSvgIcon({
    as: AngleUpSvg,
    ariaLabel: 'angle up',
    category: '',
    displayName: 'AngleUp'
});
export default AngleUp;
