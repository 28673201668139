// Generated by script, don't edit it please.
import createSvgIcon from '../createSvgIcon';
import ArrowDownSvg from '../icons/direction/ArrowDown';
var ArrowDown = createSvgIcon({
    as: ArrowDownSvg,
    ariaLabel: 'arrow down',
    category: 'direction',
    displayName: 'ArrowDown'
});
export default ArrowDown;
